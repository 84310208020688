// Section1.js
import React from 'react';
import './Section1.css';

function Section1() {
  return (
    <div className="section1">
       <div className="banner">
        <p>UK #1 Website Developer Price Comparison Site</p>
      </div>
      <div className='section1_main'>
      <div className="content-wrapper">
        <div className="text">
          {/* Text content */}
          <h2>Don't waste time searching multiple sites for quotes? Do it with a single <span style={{fontWeight: 'bold', color: 'darkgreen'}}>Click</span></h2>


     
        </div>
        <div className="image-placeholder">
          {/* Placeholder for image */}
          <video width="auto" height="auto" autoPlay loop muted>
              <source src="/202403031658.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
        </div>
      </div>
      </div> 
      <div className="banner">
        <p>Customers saved around <span style={{fontWeight: 'bold', color: 'darkgreen'}}>74%</span>in web design costs compared to the industry average.</p>
      </div>
      <h3>Begin your search - Enter your request </h3>
    </div>


  );
}

export default Section1;

