// Section3.js

// Import useState at the beginning of your file
import React, { useState } from 'react';
import { newIdea } from './Section2';
import './Section3.css';

// Now you can use the newIdea object in this component


function Section3({ onSubmit, onBackClick }) {
  const [features, setFeatures] = useState('');
  const [error, setError] = useState('');
  const [platform, setPlatform] = useState([]);
  // const [loading, setLoading] = useState(false); 
  

  const handleSubmit = async (event) => {
    // Update the newIdea object with the provided values
    if (!features || !platform.length) {
      setError('Please fill in all the required fields');
      return;
    }
    setError('');
  
    newIdea.features = features;
    // Reset the state or navigate to the next section if needed
    setFeatures('');
    event.preventDefault();
    const idea = newIdea.idea;
    newIdea.platformString = platform.join(', ')

     onSubmit() 
  };
  

  function toggleHighlight(event) {
    const button = event.target;
    button.classList.toggle('highlighted');
    const buttonName = button.textContent; // Assuming button text is unique

    setPlatform(prevPlatform => {
      if (prevPlatform.includes(buttonName)) {
        return prevPlatform.filter(item => item !== buttonName);
      } else {
        return [...prevPlatform, buttonName];
      }
    });
    newIdea.platform = platform; 
  }

  return (
    <div className="section3">
        <>
          {error && <p className="error-label">{error}</p>}
          <h2>App Requirements</h2>
          <label>
            Pick the platform you want your app to work on? You can choose multiple options* 
          </label>
          <div className="grid-container">
            <button onClick={toggleHighlight}>Web App</button>
            <button onClick={toggleHighlight}>iOS(Iphone)</button>
            <button onClick={toggleHighlight}>Android</button>
            <button onClick={toggleHighlight}>Other/Unkown</button>
          </div>
          <label>
            What features do you want?*
          </label>
          <textarea
            placeholder="Features and functions you want...(max char 650)"
            value={features}
            onChange={(e) => setFeatures(e.target.value)}
            className="custom-textarea-section3"
            maxLength={650} 
          />
          <div className="section3-buttons">
            <button onClick={onBackClick} className="custom-button">
              Back
            </button>
            <button onClick={handleSubmit} className="custom-button" >
              Submit
            </button>
          </div>
        </>
    </div>
  );
  
  
}

export default Section3;
