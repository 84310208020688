import React, { useState } from 'react';
import './Section3half.css';
import Emoji from 'react-emoji-render';

function QuoteContainer({ name, skills, rating, quote, onBookClick, onSubmit,email, idea,index   }) {
  const quoteUrl = process.env.REACT_APP_BACKEND_URL_QUOTE ;
  const [showModal, setShowModal] = useState(false);
  

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleBookClick = () => {
    onBookClick(name); // Set the chosenQuote to the name of the quote
    handleSubmit(); // Call handleSubmit when the book button is clicked
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${quoteUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ chosenQuote: name, idea , email }), // Assuming you want to submit the name
      });
      if (!response.ok) {
        throw new Error('Failed to submit chosen quote.');
      }
      // Handle successful submission, e.g., show a success message
      onSubmit(); // Call onSubmit after successful submission
    } catch (error) {
      console.error('Error submitting chosen quote:', error.message);
      // Handle error, e.g., show an error message to the user
    }
  };
  return (
    <div className={`container ${index === 0 ? 'first-container' : ''}`}>
      <div className="left-container">
        <h3>{name}</h3>
        <p>{rating}/5 <Emoji text=":star:" /></p>
        <button className='more-info' onClick={toggleModal}>More Info <span>&#9660;</span> </button>
      </div>
      <div className="right-container">
        <h3>Estimated Quote</h3>
        <p>£{quote}</p>
        <button className="custom-button" onClick={handleBookClick}>Book</button>
      </div>
      {index === 0 && <button className="recommended-button">Recommended</button>}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>{name} Services</h2>
            <ul>
              {skills.map((skill, index) => (
                <li key={index}> &#10004; {skill}</li>
              ))}
            </ul>
            <button onClick={toggleModal}>Close</button>
          </div>
        </div>
      )}

    </div>
  );
}

function Section3half({ onSubmit, onBackClick, agencyData,email, idea }) {
  const [chosenQuote, setChosenQuote] = useState('');
 

  const handleBookClick = (quoteName) => {
    setChosenQuote(quoteName);

  };

  return (
    <div className="section35">
      <h2>Your Quotes</h2>
      
      <div className="container-wrapper">
        {agencyData.map((agency, index) => (
          <QuoteContainer
            key={index}
            name={agency.name}
            skills={agency.skills}
            rating={agency.rating}
            quote={agency.quote}
            onBookClick={handleBookClick}
            onSubmit={onSubmit} // Pass onSubmit function to QuoteContainer
            idea = {idea}
            email = {email}
            index={index} 
          />
        ))}
      </div>
    </div>
  );
}

export default Section3half;
