import React, { useState, useEffect } from 'react';
import './Section2.css';
import Ticker from 'react-ticker';


const Section2 = ({ onSubmit }) => {
  const [faqItems, setFaqItems] = useState([
    { question: 'How do I get quotes from web developers on your comparison website?', answer: 'Getting quotes from web developers on our comparison website is easy! Simply fill out our quote request form with your project requirements, such as the type of website you need, features you want. Our platform will match you with suitable web developers who will provide you with quotes tailored to your needs.', isOpen: false },
    { question: 'Is there a cost to requesting quotes from web developers on your platform?', answer: 'No, there is no cost to request quotes from web developers on our platform. Our service is free for users looking to compare quotes and find the best web development services for their project. You can request quotes without any obligation to hire a developer.', isOpen: false },
    { question: 'How long does it take to receive quotes from web developers after submitting a quote request?', answer: 'After submitting a quote request on our platform, you can expect to receive quotes from web developers instantly and then you can go on to book your first meeting with your chosen developer.', isOpen: false },
    // Add more FAQ items as needed
  ]);
 

  

  
  const [request, setRequest] = useState('');
  
 
  const [items, setItems] = useState([
    'Static Websites',
    'Web Apps',
    'SaaS',
    'Web hosting',
    'Android Apps',
    'iOS Apps',
    'Web service',
    'Custom APIs',
    'E-commerce Platforms',
    'CRM Systems',
    'Enterprise Software',
    'Internal Business Software'
  ]);
 


  const handleInputChange = (event) => {
    setRequest(event.target.value);
  };

  const handleSubmit = () => {
    // Add the request data to the local array variable
    newIdea.idea = request;

    // Clear the request textarea
    setRequest('');

    // Invoke the onSubmit function passed from the parent component
    onSubmit();
  };

  const isButtonDisabled = request.trim() === '';

  const toggleFaqItem = (index) => {
    setFaqItems((prevItems) => {
      const newItems = prevItems.map((item, idx) =>
        idx === index ? { ...item, isOpen: !item.isOpen } : item
      );
      return newItems;
    });
  };

  

  return (
    <div className="section2">
      <textarea
        placeholder="What do you need built? E.G. Plumbing website, Financial Analysis Dashboard ect.(max char 100)"
        value={request}
        onChange={handleInputChange}
        className="custom-textarea"
        maxLength={100}
      />
      <button onClick={handleSubmit} className="custom-button" disabled={isButtonDisabled}>
        Next
      </button>
      <div className='section1_main'>
      <div className="content-wrapper">
        <div className="text">
          
        <h2>
  <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>Supercharge</span> your Search From{' '}
  <span style={{ fontWeight: 'bold', color: 'darkgreen' }}>One</span> Request, Get the best 10 Quotes from a pool of 100s of Web Developers!
</h2>


     
        </div>
        <div className="image-placeholder">
          {/* Placeholder for image */}
          <img src="/quoteimg.jpeg" alt="quoteimg" />
        </div>
      </div>
      </div> 
      <h2>We can get Quotes for  </h2>
      <div className="ticker-container">
      <Ticker>
          {() => (
            <>
              {items.map((item, idx) => (
                <span key={idx}>{item}</span>
              ))}
            </>
          )}
        </Ticker>
      </div>
      <h2>How it works </h2>
      <div className="container-2">
  <p className='How'>Enter your idea and feature requirements</p>
  <p className='How'>Add contact details</p>
  <p className='How'>Instantly get the 10 best quotes</p>
  <p className='How'>Pick your favourite one</p>
  <p className='How'>Get your website built!</p>
</div>
<h2>Don’t just take our word for it, see what our customers say</h2>
      <div className="testimonial-carousel">
  <div className="testimonial">
    <img src="Sarah.jpg" alt="Sarah" />
    <h3>Sarah Sidon</h3>
    <p>"I stumbled upon this website while searching for web design services, and I couldn't be happier! Not only did I find the perfect designer for my project, but I also saved a significant amount of money compared to other quotes I received. Highly recommended!"</p>
  </div>
  <div className="testimonial">
    <img src="Tom.jpg" alt="Tom" />
    <h3>Tom Davidson</h3>
    <p>"As a small business owner, finding affordable web design services was crucial for me. Thanks to this platform, I was able to compare prices from different designers and choose the one that best fit my budget. The process was seamless, and I'm extremely satisfied with the results!"</p>
  </div>
  <div className="testimonial">
    <img src="Andy.jpg" alt="Andy" />
    <h3>Andy Howard</h3>
    <p>"I had been procrastinating on redesigning my website due to the high costs involved. Thankfully, I came across this website, and it changed everything! Not only did I find a talented designer within my budget, but the entire process was quick and hassle-free. My new website looks amazing, and I couldn't be happier!"</p>
  </div>
</div>
<h2>FAQ</h2>
<div className="faq-section">
        {faqItems.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" style={{ fontSize: '1.2rem' }} onClick={() => toggleFaqItem(index)}>
              {item.question} {item.isOpen ? '-' : '+'}
            </div>
            {item.isOpen && <div className="faq-answer" style={{ fontSize: '1rem' }}>{item.answer}</div>}
            <hr style={{ borderTop: '2px solid #333', width: '100%' }} />
          </div>
        ))}
      </div>
    </div>
  );
}

let newIdea = {
  idea: '',
  features: '',
  name: '',
  email: '',
  company: '',
  platform: [],
  webDev : '',
  platformString : '',
};

export { newIdea, Section2 as default };
