// ErrorPage.js
import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <h1>Error</h1>
      <p>There was an error. Please try again later.</p>
    </div>
  );
};

export default ErrorPage;
