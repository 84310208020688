// Header.js
import React from 'react';
import './Header.css';  
import Emoji from 'react-emoji-render';// Assuming Header.css is in the same directory as Header.js

function Header({ onBackClick}) {
  return (
    <header>
      <div className="header-container">
        {/* Change h1 to button */}
        <button onClick={onBackClick} className="back-button">
        <Emoji text=":light_bulb:" />AppBuilderClub
        </button>
      </div>
    </header>
  );
}

export default Header;
