// Footer.js
import React from 'react';

function Footer() {
    return (
      <footer>
        <div className="footer-container">
          <h3>© 2024 AppBuilderClub. All rights reserved.</h3>
        </div>
      </footer>
    );
  }
export default Footer;
