// // App.js

// App.js
import React, { useState,useEffect } from 'react';
import Header from './Header';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section3half from './Section3half';
import Section4 from './Section4';
import Verify from './Verify';
import Footer from './Footer';
import { BrowserRouter as Router, Route, Routes,Navigate  } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import { getSolutionText } from './solutionText';


import './App.css';

function App() {

  const [currentSection, setCurrentSection] = useState('section2');
  const [showSection2, setShowSection2] = useState(true);
  const [showSection3, setShowSection3] = useState(false);
  const [showSection4, setShowSection4] = useState(false);
  const [submittedSupportData, setSubmittedSupportData] = useState(null);
  const [agencyData, setagencyData] = useState(null);
  const [email, setEmail] = useState(null);
  const [idea, setIdea] = useState(null);
  const [solution, setSolution] = useState(null);
  

  const handleSectionChange = () => {
    setShowSection2(false);
    setCurrentSection('section3');
  };

  const handleBackButtonClick = () => {
    window.location.href = '/'; 
    setShowSection2(true);
    setCurrentSection('section2');
  };

  
  const handleBackButtonClick3 = () => {
    setShowSection3(true);
    setCurrentSection('section4');
  };

  const handleBackButtonClick4 = () => {
    setShowSection3(false);
    setCurrentSection('section3');
  };

  const handleSection3Submit =  () => {
    setShowSection3(false);
    setCurrentSection('section4');
  
  };

  const handleSection3HalfSubmit =  () => {
    setCurrentSection('verify');
  };

  const handleSection4Submit = async (agencyData, email, ideaData ) => {
    setShowSection4(false);
    setagencyData(agencyData);
    setEmail(email);
    setIdea(ideaData);
    setCurrentSection('section3half');
  };

  const handleSupportSubmit = (email) => {
    setSubmittedSupportData(email);
    // Here you can perform any other necessary actions
  };

  useEffect(() => {
    // Google Analytics tracking code
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-W2WQP874M5";
    script.async = true;
    document.body.appendChild(script);

    window.dataLayer = window.dataLayer || []; // Define dataLayer as a global variable
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-W2WQP874M5');
  }, []);


  return (
    <Router>
      <div className="app-container">
        <Header onBackClick={handleBackButtonClick} />
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <React.Fragment>
                  {(currentSection === 'section1' || showSection2) && (
                    <Section1 onSubmit={() => setCurrentSection('section2')} />
                  )}
                  {currentSection === 'section2' && showSection2 && (
                    <Section2 onSubmit={handleSectionChange} />
                  )}
                  {currentSection === 'section3' && (
                    <Section3 onSubmit={handleSection3Submit} onBackClick={handleBackButtonClick}/>
                  )}
                  {currentSection === 'section4' && (
                    <Section4 onSubmit={handleSection4Submit} onBackClick={handleBackButtonClick4}/>
                  )}
                  {currentSection === 'section3half' && (
                    <Section3half onSubmit={handleSection3HalfSubmit}   agencyData={agencyData} email={email} idea={idea}/>
                  )}
      
                    {currentSection === 'verify' && (
                    <Verify/>
                  )}
                </React.Fragment> 
              }
            />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<Navigate to="/error" />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}
export default App;

 
