// Section4.js
import React, { useState } from 'react';
import './Section4.css'; // Make sure to have a Section4.css file for styling
import { newIdea } from './Section2';
import { useNavigate } from 'react-router-dom';
 



function Section4({ onBackClick, onSubmit , solution}) {
  const [name, setName] = useState('');
  const [budget, setBudget] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [everify, setIsEverify] = useState(false);
  const [error, setError] = useState('');
  const serverUrl = process.env.REACT_APP_BACKEND_URL_SEND_IDEA;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); 


  function updateNewIdea(name, email, company) {
    const updatedCompany = company.trim();

    newIdea.name = name;
    newIdea.email = email;
    newIdea.company = updatedCompany;
    newIdea.theirMaxBudget = budget;
    
  }



  const handleSubmit = async () => {
    setError('')
    updateNewIdea(name, email, company);
    // Validation check
    const isDataValid = () => {
      if (!newIdea.name || !newIdea.email) {
        console.error('Error: Name and email are required');
        setError('nameEmailRequired');
        return false;
      }
  
      // Check if email is in valid format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(newIdea.email)) {
        console.error('Error: Invalid email format');
        setError('invalidEmailFormat');
        return false;
      }
  
      // Check if corporation checkbox is checked and company name is provided
      if (!newIdea.company) {
        console.error('Error: Company name is required for corporation');
        setError('Company name is required');
        return false;
      }

      if (solution && !budget) {
        console.error('Error: Budget is required');
        setError('Budget is required');
        return false;
      }

      if (!solution) {
        newIdea.theirMaxBudget = newIdea.budget;
      }

      // if (!isCorporation) {
      //   newIdea.company = 'consumer idea';
      // }
  
      // Check if all other required fields are provided
      return true;
    };
  
    // Check if data is valid
    if (!isDataValid()) {
      console.log(name,email,company,'RESUTLS')
      console.error('Error: Data is incomplete or invalid');
      return; // Prevent submission if data is invalid
    }

    if (everify) {
      navigate('/error');
      return; // Prevent submission if data is invalid
    }
    setLoading(true);

    try {
     
      // console.log(newIdea)
      
      const response = await fetch(`${serverUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newIdea),
      });
  
      if (response.ok) {
        const data = await response.json();
        const agencyData = data.agencyData;
        const ideaData = data.trimmedIdea 
        // console.log(agencyData,email,ideaData)
        // console.log('Contact details submitted successfully!');
        onSubmit(agencyData,email,ideaData);
      } else {
        const errorMessage = await response.json();
        console.error('Submission failed:', errorMessage.error);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  return (
    <div className="section4">
      {loading ? (
        <div className="loading-container">
          <div className="loading">
            <div className="loading-circle"></div>
            <p>Calculating Quote...</p>
          </div>
        </div>
      ) : (
        solution ? (
          <div>
            <h2>{solution.part1}</h2>
            <p className='solutiontxt'>{solution.part2}</p>
            <p className='solutiontxt'>{solution.part3}</p>
            <p className='solutiontxt'>{solution.part4}</p>
            <p className='solutiontxt'>{solution.part5}</p>
            <input
              type="number"
              placeholder="Budget*"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              className="custom-input"
            />
          </div>
        ) : null
      )}
      {error && (
        <p className="error-label">
          {error === 'nameEmailRequired' && 'Name and email are required'}
          {error === 'invalidEmailFormat' && 'Invalid email format'}
          {error === 'CompanyNameIsRequired'&& 'Company name required'}
          {error === 'incompleteData' && 'Data is incomplete or invalid'}
          {error === 'BudgetIsRequired' && 'Budget required'}
        </p>
      )}
      <h2>Contact Details</h2>
      <input
        type="text"
        placeholder="Your Name*"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="custom-input"
      />
      <input
        type="email"
        placeholder="Your Email*"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="custom-input"
      />
      <div>
        <input
          type="text"
          placeholder="Company Name*"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          maxLength={60} 
          className="custom-input"
        />
      </div>
      <div className="section4-buttons">
        <button onClick={onBackClick} className="custom-button">
          Back 
        </button>
        <button onClick={handleSubmit} className="custom-button">
          Get Quotes
        </button>        
      </div>
    </div>
  );
  
}


export default Section4; 

