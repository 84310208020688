import React, { useEffect } from 'react';
import Emoji from 'react-emoji-render';
import { newIdea } from './Section2';
import './Verify.css'; // Import CSS file for styling
const gtag = window.gtag;


function Verify() {

  useEffect(() => {
    // Event snippet for Request quote conversion page
    gtag('event', 'conversion', {'send_to': 'AW-16471197002/tCuECN2UzJUZEMqKiq49'});
  }, []);

  return (
    <div className="verify">
      <h2>Great! Booking request has now been confrimed</h2>
      <p>
        You should get a confirmation email from your new web designers in under 24hours 
      </p>
      <p>
        From there you will book your first call and plan your build 
      </p>
    </div>
  );
}

export default Verify;
